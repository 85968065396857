import Masonry from 'masonry-layout'

import { initNewsItems } from './list-items/news'
import { initMediaItems } from './list-items/media'
import RegionPicker from './region-picker'
import { loadImage } from './image'
import Dropdown from './dropdown'
import DatePicker from './datepicker'
import Modal from './modal'

export const CUSTOM_BRANCH = 'Folge-ich';

function sanitizeSearch(s) {
    return !s ? s : s.replace(/[+<>:\\?*%]+/g, '');
}

const getSearch = () => {
    const ressort = $('#search_field').attr('data-branch');
    return `${sanitizeSearch($('#search_field').val())} ${ressort ? 'uncovr' + ressort : ''}`.replace(/\//g, '').trim();
}

function filterRessort(s) {
    return s.replace(/uncovr[\w]+/g, '').trim();
}

export default class FilteredList {
    constructor() {
        this.currentState = {
            filter: {
                search: getSearch()
            }
        }

        const doSearch = value => {
            if (!this.asyncList.length) document.location = $('body').attr('data-baseurl') + '/' + encodeURIComponent(value) + '/';
            else this.refreshFilter();
        }

        $('#search_field').on('keydown', function (e) {
            if (e.which === 13) {
                doSearch(getSearch());
                return false;
            }
        });

        $('#search_field + button').on('click', function (e) {
            doSearch(getSearch());
            return false;
        });

        $('[data-search="#search_field"]').on('click', function (e) {
            doSearch(getSearch());
            return false;
        });

        if (this.initList()) {
            this.initFilter();
            this.initPagination();
        }
        this.initLayout();
    }

    initFilter() {
        new RegionPicker($('#region-search'), () => this.refreshFilter());

        $('.save-search').on('click', (ev) => {
            if ($(ev.currentTarget).attr('href')) return true;
            if ($(ev.currentTarget).hasClass('checked')) return false;

            const search = encodeURIComponent(getSearch() || '');
            const branch = $(`input[name="filter_branches"]`).val();
            const region = $('#region-search').attr('data-value') || '';
            const $this = $(ev.currentTarget);
            $.post('/User/Search/Save/' + (region ? ('region-' + region + '/') : '') + (search ? (search + '/') : '') + branch ).then(result => {
                if (!result.error) {
                    if ($(ev.currentTarget).attr("data-reference")) {
                        $.post('/User/Search/ToggleReference/' + result.id).then(result => {
                            $this.addClass('checked');
                            $this.find('.label').html($this.attr('data-label-saved'));
                        });
                    } else {
                        $this.addClass('checked');
                        $this.find('.label').html($this.attr('data-label-saved'));
                    }
                } else {
                    new Modal().show(result.msg);
                }
            });
            return false;
        });

        this.datePicker = new DatePicker($('.dropdown.date'), { onChange: () => this.refreshFilter() });
        this.dropdowns = [];
        $('.dropdown').each((i, e) => this.dropdowns.push(new Dropdown(e)));
    }

    initPagination() {
        $('.action-prev, .action-next').on('click', ({ currentTarget }) => {
            const max = this.asyncList.attr('data-append-max');
            if (!max || this.asyncList.children().length < max) {
                const button = $(currentTarget);
                if (button.hasClass('loading')) { return false }
                button.addClass('loading');
                this.goTo(button.attr('href'), Object.assign({ filter: {} }, window.history.state)).then(() => {
                    button.removeClass('loading');
                    this.maybeLoadMore();
                });
                return false;
            }
        });

        if ($('.trigger-on-show').length > 0) {
            $(window).on('scroll', () => this.maybeLoadMore());
            this.maybeLoadMore();
        }

        this.asyncList.find('[data-prev]').removeAttr('data-prev');
        this.asyncList.find('[data-next]').removeAttr('data-next');
    }

    initLayout() {
        $('[data-layout="masonry"]').each(function () {
            this.masonry = new Masonry(this, {
                percentPosition: true,
                columnWidth: '.grid-sizer',
                gutter: '.gutter-sizer',
                itemSelector: '.image-item',
                transitionDuration: 0
            })
        });

        $('.switch-list').on('click', ({ currentTarget }) => {
            const $target = $(currentTarget);
            const mode = $target.attr('data-mode');
            let modeClass = mode;
            if (mode === '' && (!this.currentState.filter.search || this.currentState.filter.search.startsWith("uncovr"))) {
                modeClass = 'collapsed';
            }

            $('.layout-list').removeClass('collapsed open open-with-image').addClass(modeClass);
            $('.switch-list').removeClass('active');
            $target.addClass('active');
            document.cookie = `listMode=${mode}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        });
    }

    initList() {
        initNewsItems();
        initMediaItems();

        this.asyncList = $('.asynclist');

        if (this.asyncList.length === 1) {
            const branch = $(`input[name="filter_branches"]`).val();
            const search = encodeURIComponent(getSearch());
            const region = $('#region-search').attr('data-value');
            const regionLabel = $('#region-search').attr('data-label');

            history.replaceState({
                filter: {
                    search,
                    branch,
                    region,
                    regionLabel,
                },
                url: document.location.pathname
            }, document.title, document.location.toString());

            const $new = $('#new-messages');
            if ($new.length > 0) {
                let now = $new.attr('data-now');
                setInterval(() => {
                    const { search, branch, region } = this.currentState.filter;
                    $.post($(document.body).attr('data-baseurl') + '/Update/' + now + '/' + (region ? ('region-' + region + '/') : '') + (search ? (search + '/') : '') + (branch ? (branch + '/') : '')).then(result => {
                        if (result.count > 0) {
                            $new.attr('data-now', result.now);
                            now = result.now;
                            const items = $(result.html);
                            const count = result.count;
                            const $list = $('#item-list');
                            items.addClass('fly-in').each(function (index) {
                                $(this).css('animation-delay', (.5 + (count - index - 1) * .1) + 's');
                            });
                            this.initNewContent(items, false);
                            const h = $list.outerHeight();
                            $list.prepend(items);
                            const diff = h - $list.outerHeight();
                            $list.css({
                                'margin-top': diff + 'px',
                                'transform': 'translateY(0)',
                                'transition': 'transform .8s ease-in-out',
                            });
                            setTimeout(() => {
                                $list.css({
                                    'transform': 'translateY(' + (-diff) + 'px)',
                                });
                                setTimeout(() => {
                                    $list.css({
                                        'margin-top': '',
                                        'transform': '',
                                        'transition': '',
                                    });
                                }, 850);
                            }, 1);
                        }
                    });
                }, 60000)
            }

            /**
            * Listen for navigation via history
            */
            $(window).on('popstate', event => {
                const state = event.originalEvent.state;
                if (state && state.filter) {
                    const { filter: { search, branch, region, regionLabel = '', date } = {} } = state;
                    $('#search_field').val(filterRessort(decodeURIComponent(search).replace(/\//g, '')) || '');
                    $('#region-search').attr('data-value', region);
                    $('#region-search').attr('data-label', regionLabel);
                    $('#region-search input').val(regionLabel);
                    this.datePicker.value = date;
                    this.refreshToolbar(branch, search, region);
                } else {
                    $('#search_field').val('');
                    $('#region-search').attr('data-value', '');
                    $('#region-search').attr('data-label', '');
                    $('#region-search input').val('');
                    this.datePicker.value = null;
                    this.refreshToolbar();
                }
                if (state && state.page) {
                    $('body').attr('data-page', state.page);
                } else {
                    $('body').attr('data-page', 1);
                }

                var url = $('body').attr('data-baseurl');
                if (state) {
                    url = state.url;
                    this.currentState = state;
                }
                this.loadUrl(url, true);
                this.updateMainLinks(url);

                this.dropdowns.forEach(dd => dd.refresh());
            });


            return true;
        }
        return false;
    }

    /**
    * Maybe load more content if navigation is in viewport
    */
    maybeLoadMore() {
        const windowTop = $(window).scrollTop();
        const windowBottom = windowTop + window.innerHeight;
        $('.trigger-on-show').each(function () {
            const $this = $(this);
            if ($this.hasClass('triggered')) return;
            var offset = $this.offset();
            if (offset.top > windowTop && offset.top - 200 < windowBottom || offset.bottom > windowTop && offset.bottom < windowBottom) {
                $this.addClass('triggered').triggerHandler('click');
            }
        });
    }


    initNewContent(elems, updatePagination = true) {
        if (this.asyncList.attr('data-layout') === 'masonry') {
            this.asyncList[0].masonry.appended(elems);
            this.asyncList[0].masonry.layout();
        }
        loadImage(elems.find('img'));
        initMediaItems(elems);
        initNewsItems(elems);
        if (updatePagination) {
            const prevUrl = elems.filter('[data-prev]').attr('data-prev');
            const nextUrl = elems.filter('[data-next]').attr('data-next');
            elems.filter('[data-prev]').removeAttr('data-prev');
            elems.filter('[data-next]').removeAttr('data-next');
            if (prevUrl && prevUrl !== '') {
                $('.action-prev').attr('href', prevUrl).show();
            } else {
                $('.action-prev').hide();
            }
            if (nextUrl && nextUrl !== '') {
                $('.action-next').attr('href', nextUrl).show();
            } else {
                $('.action-next').hide();
            }
        }
        $('.triggered').removeClass('triggered');
        $('main').removeClass('loading');
    }



    /**
    * Fetch settings from filter and refresh content
    */
    refreshFilter() {
        const branch = $(`input[name="filter_branches"]`).val();
        const date = this.datePicker.value;
        const search = encodeURIComponent(getSearch());
        const region = $('#region-search').attr('data-value');
        const regionLabel = $('#region-search').attr('data-label');
        const baseUrl = $('body').attr('data-baseurl');

        this.refreshToolbar(branch, search, region);

        this.dropdowns.forEach(dd => dd.refresh());

        const parts = [date, region ? ('region-' + region) : '', search, branch].filter(p => p && p !== '');
        let url = baseUrl + '/' + parts.join('/') + '/';
        url = url.replace('Alle/', '');

        this.updateMainLinks(url);

        this.goTo(url, {
            filter: {
                search,
                branch,
                region,
                regionLabel,
                date,
            }
        }, true).then(() => setTimeout(() => this.maybeLoadMore(), 400));
    }

    refreshToolbar(branch, search = '', region  = '') {
        branch = branch || 'Alle';

        $('.ressort-highlights').toggleClass('ressort-highlights--hide', !!region);

        $('.tools .custom').toggleClass('hidden', branch !== CUSTOM_BRANCH);
        if (branch === CUSTOM_BRANCH || branch === 'Alle' && !search && !region || [
            "EANS-Adhoc",
            "Pressekonferenz",
            "Veranstaltung",
            "Auszeichnung",
            "Studie",
            "Umfrage",
            "Ranking"
        ].indexOf(decodeURIComponent(search).slice(1)) > -1) {
            $('.save-search').addClass('hidden');
        } else {
            $('.save-search').removeClass('hidden');
            $.post('/User/Search/Has/' + (region ? ('region-' + region + '/') : '') + (search ? (search + '/') : '') + branch).then(result => {
                $('.save-search').toggleClass('checked', !!result && result.has).find('.label').html($('.save-search').attr(!!result && result.has ? 'data-label-saved' : 'data-label'));
            }).fail(() => $('.save-search').toggleClass('checked', false).find('.label').html($('.save-search').attr('data-label')));
        }
    }

    /**
    * Update the links in the section dropdown according to the filter in the given url
    */
    updateMainLinks(url) {
        url = url.replace(/^\/otxFrontend/, '');
        url = url.replace(/\/\d{2}-\d{2}-\d{4}/, '');
        url = url.replace(/(Newsroom|Event-von)\/\d+/, '$1');
        url = url.split('/').slice(2).join('/');
        $('#main-links a').each(function () {
            $(this).attr('href', ($(this).attr('data-base') + '/' + url).replace(/\/\d+\/?$/, '/'));
        });
    }

    /**
    * go to the given url & state
    */
    goTo(url, state, refresh) {
        return this.loadUrl(url, refresh).then(result => {
            this.currentState = state;
            if (state && refresh || !this.asyncList.attr('data-mode')) {
                state.url = url;
                $("html, body").animate({ scrollTop: 0 }, "slow");
                history.pushState(state, "News | uncovr", url);
            }

            if (state && window.gtag) {
                window.gtag('config', 'UA-109728092-1', { 'page_path': url });
            }
        });
    }

    /**
    * load the given url and replace the asynclist with the new content
    */
    loadUrl(url, refresh) {
        $('main').addClass('loading');
        return $.get(url).then(result => {
            if (this.asyncList.attr('data-mode') === 'append' && !refresh) {
                const elems = $(result).appendTo(this.asyncList).fadeIn();
                this.initNewContent(elems);
            } else {
                this.asyncList.fadeOut(() => {
                    this.asyncList.find(':not(.persistent)').remove();
                    this.asyncList.append(result).fadeIn();
                    this.initNewContent(this.asyncList.children());

                    const mode = $('.switch-list.active').attr('data-mode');
                    let modeClass = mode;
                    if (mode === '' && !this.currentState.filter.search) {
                        modeClass = 'collapsed';
                    }
                    $('.layout-list').removeClass('collapsed open').addClass(modeClass);
                });
            }
        }).fail(() => $('main').removeClass('loading'));
    }
}
