export default class NewsModePicker {

    constructor(elem, onChange) {
        const $elem = this.$elem = $(elem);
        const $input = this.$input = $elem.find('input');

        $input.on('change', e => {
            this.selectMode(e.currentTarget.value, e.currentTarget.parentElement.textContent);
        });

        this.highlightedRegionHint = -1;
        this.onChange = onChange;
    }

    selectMode(id, label) {
        const { $elem, $input } = this;
        if (label) label = label.replace(/<span>.*?<\/span>/g, '').trim();
        $elem.attr('data-value', id);
        if (label) {
            $elem.attr('data-label', label);
            if ($input.attr("type") === "radio") {
                $elem.find('.news-mode-picker__value').html(label);
            }
        }
        clearTimeout(this.regionTimeout);
        $elem.removeClass('progress');
        $elem.find('.options').html('');
        document.cookie = `news_list_mode=${id}; path=/;`;
        this.onChange && this.onChange();
    }
}