import CookiePopup from './widgets/cookie-popup'
import FilteredList from './widgets/filtered-list'
import { loadImage } from './widgets/image'
import { findPasswordToggle } from './widgets/password'
import DatePicker from './widgets/datepicker'
import Modal from './widgets/modal'
import { shuffleArray } from './helpers' 
import { initNews } from './widgets/news'
import { initNav } from './widgets/nav'
import RegionPicker from './widgets/region-picker'
import NewsModePicker from './widgets/news-mode-picker'

$(() => {
    new FilteredList();

    loadImage($('img'));

    findPasswordToggle();

    $('.autofocus').focus();

    $('.hide-js').hide();

    $('.back-to-top').on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 'slow');
        return false;
    });

    $(window).on('scroll', function () {
        $(document.body).toggleClass('scrolled', $(window).scrollTop() > 0);
        $('.back-to-top').toggleClass('show', $(window).scrollTop() > 200);
    });
    $(document.body).toggleClass('scrolled', $(window).scrollTop() > 0);

    initNav();

    $('.dropdown.sections a').on('click', function () {
        var dropdown = $(this).parents('.dropdown').addClass('close');
        setTimeout(function () { dropdown.removeClass('close'); }, 10);
    });

    new CookiePopup();

    $('.dropdown input').on('change', e => {
        if (e.currentTarget.checked) {
            $(e.currentTarget).parents('.dropdown').find('.label').html($(e.currentTarget).next().html());
        }
    });

    $('[href="#toggle-subscription"], [data-action="toggle-subscription"]').click(function () {
        if (this.disabled) { return false; }
        const item = $(this).parents('[data-id]');
        const id = item.attr('data-id');
        item.toggleClass('off');
        $(this).attr('title', $(this).attr(`data-title-${item.hasClass('off') ? 'disabled' : 'enabled'}`));
        $.post('/User/Subscription/Toggle/' + id).then(result => {
            item.toggleClass('off', result.disabled);
            $(this).attr('title', $(this).attr(`data-title-${item.hasClass('off') ? 'disabled' : 'enabled'}`));
        });
        return false;
    });

    $('[href="#remove-subscription"], [data-action="remove-subscription"]').click(function () {
        if (this.disabled) { return false; }
        const item = $(this).parents('[data-id]');
        const id = item.attr('data-id');
        const done = item.slideUp().promise();
        $.post('/User/Unsubscribe/' + id).then(result => {
            if (result.error) {
                item.slideDown();
            } else {
                done.then(() => item.remove());
            }
        });
        return false;
    });

    $('[data-action="subscribe"]').click(ev => {
        const target = ev.currentTarget;
        const $target = $(target);
        const item = $target.parents('[data-id]');
        const id = item.attr('data-id');

        if (target.disabled || !id) { return false; }

        const titleOn = $target.attr('data-title-on');
        const titleOff = $target.attr('data-title-off');
        const analyticsMonitoringMode = titleOn && titleOff;

        if (analyticsMonitoringMode && $target.attr('data-on') !== undefined) {
            $.post('/User/Unsubscribe/' + id).then(result => {
                if (!result.error) {
                    item.addClass('analytics-list__item--uncovr')
                    $target.attr("data-on", null);
                    $target.attr("title", $target.attr("data-title-off"));
                    item.find('.analytics-list__tools > *').attr('disabled', true);
                } else {
                    new Modal().show(result.msg);
                }
            });
        } else {
            $.post('/User/Subscribe/' + id).then(result => {
                if (!result.error) {
                    item.removeClass('analytics-list__item--uncovr')
                    item.find('[disabled]').removeAttr('disabled');
                    if (analyticsMonitoringMode) {
                        $target.attr("data-on", true);
                    } else {
                        target.disabled = true;
                    }
                } else {
                    new Modal().show(result.msg);
                }
            });
        }
        return false;
    }).filter("[data-title-on][data-title-off]").each((idx, el) => {
        $(el).attr("title", $(el).attr(`data-title-${$(el).attr("data-on") !== undefined ? "on" : "off"}`))
    });;

    $('[data-action="save-search"]').on('click', (ev) => {
        const target = ev.currentTarget;
        const $target = $(target);
        const search = $target.attr('data-search');
        if (target.disabled || !search) return false;

        const titleOn = $target.attr('data-title-on');
        const titleOff = $target.attr('data-title-off');
        const analyticsMonitoringMode = titleOn && titleOff;
        if (analyticsMonitoringMode && $target.attr('data-on') !== undefined) {
            const id = $target.parents('[data-id]').attr("data-id");
            if (!id) { return false }
            $.post(`/User/Search/Delete/${encodeURIComponent(id)}`).then(result => {
                if (!result.error) {
                    $target.attr("data-on", null);
                    $target.attr("title", $target.attr("data-title-off"));
                    $target.parents('.analytics-list__item')
                        .addClass('analytics-list__item--uncovr')
                        .attr('data-id', null)
                        .find('.analytics-list__tools > *')
                            .attr('disabled', true);
                } else {
                    new Modal().show(result.msg);
                }
            });
        } else {
            $.post(`/User/Search/Save/${encodeURIComponent(search)}/Alle`).then(result => {
                if (!result.error) {
                    if (analyticsMonitoringMode) {
                        $target.attr("data-on", true);
                    } else {
                        target.disabled = true;
                    }
                    $target.parents('.analytics-list__item')
                        .removeClass('analytics-list__item--uncovr')
                        .attr('data-id', result.id)
                        .find('.analytics-list__tools > *')
                            .attr('disabled', false);
                } else {
                    new Modal().show(result.msg);
                }
            });
        }
        return false;
    }).filter("[data-title-on][data-title-off]").each((idx, el) => {
        $(el).attr("title", $(el).attr(`data-title-${$(el).attr("data-on") !== undefined ? "on" : "off"}`))
    });

    $('[href="#toggle-filter"], [data-action="toggle-filter"]').click(function () {
        if (this.disabled) { return false; }
        const item = $(this).parents('[data-id]');
        const id = item.attr('data-id');
        item.toggleClass('off');
        $(this).attr('title', $(this).attr(`data-title-${item.hasClass('off') ? 'disabled' : 'enabled'}`));
        $.post('/User/Search/Toggle/' + id).then(result => {
            item.toggleClass('off', result.disabled);
            $(this).attr('title', $(this).attr(`data-title-${item.hasClass('off') ? 'disabled' : 'enabled'}`));
        });
        return false;
    });

    $('[href="#remove-filter"], [data-action="remove-filter"]').click(function () {
        if (this.disabled) { return false; }
        const item = $(this).parents('[data-id]');
        const id = item.attr('data-id');
        const done = item.slideUp().promise();
        $.post('/User/Search/Delete/' + id).then(result => {
            if (result.error) {
                item.slideDown();
            } else {
                done.then(() => item.remove());
            }
        });
        return false;
    });

    $('[href="#toggle-filter-reference"], [data-action="toggle-filter-reference"]').click(function () {
        if (this.disabled) { return false; }
        const item = $(this).parents('[data-id]');
        const id = item.attr('data-id');
        const done = item.slideUp().promise();
        $.post('/User/Search/ToggleReference/' + id).then(result => {
            if (result.error) {
                item.slideDown();
            } else {
                done.then(() => item.remove());
            }
        });
        return false;
    });

    function updatePricingTableInterval() {
        const checked = $('#pricing-toggle').is(':checked');
        $('.user-pricing__package--yearly').toggle(checked)
        $('.user-pricing__package--monthly').toggle(!checked)
    }
    $('#pricing-toggle').change(updatePricingTableInterval);
    updatePricingTableInterval();

    $('.user-pricing__package input').change((e) => {
        $(e.target).parents('form').submit();
    });

    $('[href="#cancel-package"]').on('click', e => {
        const content = $(`<div>
            Sind Sie sicher, dass Sie Ihr Abo wirklich kündigen wollen? Das Abo endet damit am ${$(e.currentTarget).attr('data-date')} und wird nicht automatisch verlängert.
            <div class="modal-content__button-row modal-content__button-row--left">
                <button id="yes-cancel-package">Ja, Abo kündigen</button>
                <button class="inverted-blue-bordered" id="no-cancel-package">Nein, doch nicht</button>
            </div>
        </div>`);
        const modal = new Modal().show(content, "Abo wirklich kündigen?");
        content.find('#yes-cancel-package').on('click', () => {
            modal.progress();
            $.post('/CancelPackage').then(result => {
                modal.progress(false);
                if (result.error) {
                    modal.content(result.msg, "Abo kündigen");
                } else {
                    modal.content(result.msg, "Abo kündigen");
                }
            })
        })
        content.find('#no-cancel-package').on('click', () => {
            modal.close();
        })
        return false;
    });

    $('#ressort-select > *').on('click', () => {
        $('#ressort-list')
            .toggleClass('head__ressort-list--show')
            .height($('#ressort-list').hasClass('head__ressort-list--show') ? $('#ressort-list')[0].scrollHeight : 0);
    })

    if ($('.ressort-highlights').length) {
        $('.ressort-highlights').css('--height', $('.ressort-highlights')[0].scrollHeight + 'px');
    }

    //trending date picker
    if ($('.dropdown.date-trending').length) {
        new DatePicker($('.dropdown.date-trending'), {
            onChange: value => {
                window.location = `/News/Trending/${value.replaceAll(".", "-")}`;
            }
        });
    }

    //trending hero
    if ($('.trending-hero').length) {
        const options = shuffleArray($('.trending-list')
            .map((idx, el) => $(el).find('li').slice(0, 3))
            .toArray()
            .reduce((agg, items) => [...agg, ...items], []));
        let currentIndex = 0;

        function scheduleTrendingHeroUpdate() {
            setTimeout(() => {
                $('.trending-hero__progress').css({ animation: "trending-reset 0s 0s both 1" });
                setTimeout(() => {
                    $('.trending-hero__progress').css({ animation: "" });
                }, 1);

                currentIndex++;
                if (currentIndex >= options.length) {
                    currentIndex = 0;
                }

                updateTrendingHero();
                scheduleTrendingHeroUpdate();
            }, 6100);
        }

        function updateTrendingHero() {
            const $item = $(options[currentIndex]);
            const keyword = $item.find('.trending-list__label').text().trim();
            const title = $item.parents('section').find('.trending-list__title').text();

            $('.trending-hero__title').html(`${title}<br><em>${keyword}</em>`);
            $('#search_field').val(`#${keyword}`)
        }

        updateTrendingHero();
        scheduleTrendingHeroUpdate();
    }

    //trending news
    if ($('.trending-news').length) {
        fetch('https://presse.uncovr.com/Ajax.aspx/GetNewsData', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                checkID: "0f8fad5b-d9cb-469f-a165-70867728950e",
                server: "https://presse.uncovr.com/",
                clientID: 25,
                groupID: 285,
                masterGroupID: 285,
                languageID: 23,
                menueID: 31132,
                treshold: 4,
                isLogged: false,
                newsId: -1,
                startIndex: 1,
            })
        }).then(d => d.json())
            .then(({ d }) => {
            for (let item of d.Items) {
                $('.trending-news').append(`<div class="trending-news__item col-xs-12 col-sm-3">
                    <div class="trending-news__image">${item.Image.replace(/<a [^>]+>(.*)<\/a>/g, "$1")}</div>
                    <div class="trending-news__title">${item.Title.replace(/<a [^>]+>(.*)<\/a>/g, "$1")}</div>
                    <div class="trending-news__text">${item.Text.replace(/<a [^>]+>(.*)<\/a>/g, "$1")}</div>
                    <a class="trending-news__link" target="_blank" href="${item.Title.match(/href="([^"]+)"/)[1]}"></a>
                </div>`);
            }
        });
    }

    //jotforms
    $('a[href^="#jotform-"]').each((idx, el) => {
        $(el).on('click', (e) => {
            e.stopPropagation();
            e.preventDefault();

            if (!window["JotformFeedback"]) {
                return false;
            }

            const id = $(e.currentTarget).attr('href').split('-')[1];

            new JotformFeedback({
                formId: id,
                base: 'https://form.jotform.com/',
                background: '#FFA500',
                fontColor: '#FFFFFF',
                type: 1,
                height: 500,
                width: 700,
                openOnLoad: true
            });

            return false;
        });
    });

    //uncovr news
    initNews();

    //news ticker
    const newsTicker = $('.news-ticker');
    if (newsTicker.length) {
        const token = newsTicker.attr("data-token");
        fetch(`/Json/${token}/uncovrTop_500/1`).then((d) => d.json()).then((data) => {
            const html = ['<li>Originaltext Stream</li>', ...new Set(data.items.map((item) => `<li><time datetime="${item.date.split(' ')[1].split(':').slice(0, 2).join(':')}">${item.date.split(' ')[1].split(':').slice(0, 2).join(':') }</time>${item.company.shortName}</li>`))].join('');
            newsTicker.html(`<ul class="news-ticker__list">${html}</ul>`);
            const list = newsTicker.children().first();
            const lw = list.width();
            list.html(html + html);
            let lastT = performance.now();
            let shift = 0;
            const tick = (t) => {
                const dt = Math.round((t - lastT) * .2);
                lastT = t;
                shift = (shift - dt) % lw;
                list.css("transform", `translateX(${shift}px)`);
                requestAnimationFrame(tick);
            };
            tick(lastT);
        });
    }


    //trending news
    const trendingTopicNewsTemplate = $('#trending-topics-article')[0];
    $('.trending-topics__item').each((i, el) => {
        const item = $(el);

        setTimeout(() => {
            fetch(item.attr("data-url"), {
                priority: 'low'
            }).then(d => d.json()).then(data => {
                for (let newsItem of data.items) {
                    const news = trendingTopicNewsTemplate.content.cloneNode(true);
                    $(news).find('.trending-topics__article__title').html(newsItem.title);
                    $(news).find('.trending-topics__link')
                        .html(`<span class="icon icon-export"></span> ${newsItem.url.replace(/https?:\/\//, '').split('/')[0]}`)
                        .attr('href', newsItem.url);

                    item.append(news);
                }
            });
        }, Math.random() * 500)
    });

    //region select nav
    if ($('#region-search-nav').length) {
        new RegionPicker($('#region-search-nav'), () => window.location.reload());
    }

    //news mode select nav
    if ($('#news-mode-nav').length) {
        new NewsModePicker($('#news-mode-nav'), () => window.location.reload());
    }

    //persist form data
    $('form[id] *[data-persist-form]').on('click', (event) => {
        const form = event.currentTarget.closest('form[id][data-persisted]');
        const fd = new FormData(form);
        const o = {};
        fd.keys().forEach((k) => o[k] = k.endsWith("[]") ? fd.getAll(k) : fd.get(k));
        window.sessionStorage.setItem(form.id, JSON.stringify(o));
    })

    $('form[id] *[data-clear-persist-form]').on('click', (event) => {
        const form = event.currentTarget.closest('form[id][data-persisted]');
        window.sessionStorage.removeItem(form.id);
    })

    $('form[id][data-persisted]').each((idx, form) => {
        let data = window.sessionStorage.getItem(form.id);
        if (data) {
            data = JSON.parse(data);

            $(`select option`).attr('selected', false)
            $(`input`).attr('checked', false)
            $(`input[type="text"]`).attr('checked', false).val("");

            Object.entries(data).forEach(([key, value]) => {
                if (key.endsWith("[]")) {
                    value.forEach((v) => {
                        $(`select[name="${key}"] option[value="${v}"]`).attr('selected', true)
                        $(`input[name="${key}"][value="${v}"]`).attr('checked', true)
                    })
                } else {
                    $(`[name="${key}"]`, form).val(value);
                }
            })

            $('[data-options-fill]', form).each((idx, el) => {
                el["__getSelection"] = (name) => {
                    return data[name];
                }
            })
        }
    })
});


