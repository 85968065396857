import Dropdown from './dropdown';
import AirDatepicker from "air-datepicker";
import localeDe from 'air-datepicker/locale/de';

export default class DatePicker extends Dropdown {
    constructor(elem, options) {
        super(elem);
        if (!this.elem || !this.elem.length) { return null }
        const { onChange } = options;
        const $target = this.elem.find('.datepicker-slot'); 
        this.datepicker = new AirDatepicker($target[0], {
            locale: localeDe,
            buttons: {
                content: 'Heute',
                onClick: (dp) => {
                    let date = new Date();
                    dp.selectDate(date);
                    dp.setViewDate(date);
                }
            },
            multipleDatesSeparator: ' - ',
            onSelect: ({ date, formattedDate }) => {
                const value = this.elem.attr('data-value');

                if (formattedDate === '') {
                    var p = value.split('.');
                    this.datepicker.selectDate(new Date(p[2], p[1] - 1, p[0]));
                    return;
                }

                if (value !== formattedDate) {
                    this.elem.attr('data-value', formattedDate);
                    onChange && onChange(formattedDate);
                }
                this.refresh();
            }
        });
        this.elem.data('formatValue', function (v) {
            return v.replace(/\d{4}/, '');
        });
        const p = (this.elem.attr('data-value') || '').split('.');
        if (p && p.length >= 3) { this.datepicker.selectDate(new Date(p[2], p[1] - 1, p[0])); }
    }

    get value() {
        const value = this.elem.attr('data-value');
        var def = this.elem.attr('data-default');
        return value === def ? '' : value.replace(/\./g, '-');
    }

    set value(val) {
        if (val) {
            const p = val.split('-');
            this.elem.attr('data-value', val.replace(/-/g, '.'));
            this.datepicker.selectDate(new Date(p[2], p[1] - 1, p[0]));
        } else {
            this.elem.attr('data-value', this.elem.attr('data-default'));
            this.datepicker.selectDate(new Date());
        }
    }
}